import React from 'react';
import UploadCourse from '../../../components/Admin/GrowthTools/UploadCourse';
import Authenticate from '../../../components/Identity/Authenticate';
import * as styles from '../../../styles/admin/Account.module.scss';
import Layout from '../../../components/Admin/Layout';
import browseTabs from '../../../browseTabs';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import { GrowthToolTabs } from '../../../types/commons';

type Props = {
  identityProviderClient: any,
}

const UploadCoursePage:React.FC<Props> = ({ identityProviderClient }) => (
  <AdminProvider>
    <div className={styles.accountPageWrapper}>
      <Layout
        title="Growth Tools"
        Component={() => <UploadCourse />}
        tabs={browseTabs}
        activeTab={GrowthToolTabs.UPLOAD_COURSE}
        identityProviderClient={identityProviderClient}
      />
    </div>
  </AdminProvider>
);

export default Authenticate(UploadCoursePage);
