const browseTabs = {
  activeTab: 'Assign Courses',
  tabData: [
    {
      name: 'Assign Courses',
      route: '/admin/growth-tools/assign-courses/',
    },
    {
      name: 'Hide Courses',
      route: '/admin/growth-tools/hide-courses/',
    },
    {
      name: 'Upload Course',
      route: '/admin/growth-tools/upload-course/',
    },
  ],
};
export default browseTabs;
