import React, { useState, useEffect, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import * as styles from '../../../styles/admin/UploadCourse.module.scss';
import UploadCoursePopup from './UploadCoursePopup';
import { toaster } from '../../../services/utils';
import { fetchPrivateCoursesByEnterpriseId } from './UploadCourseService';
import AdminContext from '../../../context/Admin/AdminContext';
import { Course, CourseStatus } from '../../../API';
import { GrowthToolTabs } from '../../../types/commons';
import { getEnterpriseProfile } from '../../GraphQL/Services';

const CourseStatusMapping = {
  [CourseStatus.UPLOAD_FAILED]: 'Upload Failed',
  [CourseStatus.PROCESSING]: 'Processing',
};

const UploadCourse:React.FC = () => {
  const { companyInfo, setCompanyInfo } = useContext(AdminContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [privateCourses, setPrivateCourses] = useState<Course[]>([]);

  const onSuccess = async (message:string) => {
    await fetchPrivateCourses();
    if (!companyInfo?.scormCloudAppID) {
      const companyProfileInfo = await getEnterpriseProfile(companyInfo.id);
      setCompanyInfo(companyProfileInfo);
    }
    setIsModalVisible(false);
    toaster(message);
  };

  const navigateToCourseDetailsPage = (courseId:string) => {
    navigate(`/admin/course-details/${courseId}`, { state: { sourceTab: GrowthToolTabs.UPLOAD_COURSE } });
  };

  const fetchPrivateCourses = async () => {
    try {
      const courses = await fetchPrivateCoursesByEnterpriseId(companyInfo.id);
      setPrivateCourses(courses);
    } catch (e) {
      setPrivateCourses([]);
    }
  };

  useEffect(() => {
    if (companyInfo?.id) {
      if (!companyInfo.scormCloudAppID) {
        navigate('/admin/overview/');
        return;
      }
      fetchPrivateCourses();
    }
  }, [companyInfo?.id]);

  return (
    <div className={styles.uploadCourseContainer}>
      <h1>Upload Courses</h1>
      <h5>
        Below are your uploaded courses, to assign courses to your learners now,
        click on the &#39;Assign Courses&#39; tab and ensure your learners complete the course
      </h5>
      <div className={styles.courseCardList}>
        <button
          id="admin-growth-tools-upload-course-upload-new-course"
          type="button"
          className={styles.uploadCourse}
          onClick={() => setIsModalVisible(true)}
        >
          <StaticImage
            src="../../../../static/images/admin/cloud.png"
            alt="upload"
            placeholder="blurred"
            objectFit="contain"
          />
          <p>Upload</p>
          <p>New Course</p>
        </button>
        {
          privateCourses?.length > 0 && privateCourses.map((course) => (
            <div className={styles.card} key={course?.id}>
              <div
                role="button"
                onClick={() => navigateToCourseDetailsPage(course.id)}
                aria-hidden
              >
                <img
                  className={styles.cardImage}
                  src={course.courseHeroBannerImage?.href}
                  alt={course.courseName}
                />
                <div className={styles.cardInfo}>
                  <h1>{course?.courseName}</h1>
                  {course?.status && course.status !== CourseStatus.AVAILABLE
                   && <p>{CourseStatusMapping[course.status]}</p>}
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <UploadCoursePopup
        isVisible={isModalVisible}
        onSuccess={onSuccess}
        onCancel={() => setIsModalVisible(false)}
        domIDs={{
          cta: 'admin-growth-tools-upload-course-upload-course-popup-upload',
        }}
      />
    </div>
  );
};

export default UploadCourse;
